import {
  CHECKBOX_LEF, L_CURRENCY_MASK_INPUT, SELECT_INPUT,
  TEXT_INPUT,
} from '@/views/components/DynamicForm/constants'

export default function config() {
  const ACCESS_ABILITY_RATES = { action: 'change', subject: 'Rates' }
  const tableColumns = [
    // {
    //   key: 'batchdelete',
    //   label: '',
    //   thStyle: { width: '30px' },
    // },
    { key: 'actions', label: '', thStyle: { width: '50px' } },
    {
      key: 'base_rate',
      label: 'Base Rate',
    },
    {
      key: 'pricing_period_days',
      label: 'Pricing Period Days',
    },
    {
      key: 'status',
      label: 'Status',
    },
  ]
  const fields = {
    pricing_rule_id: {
      type: SELECT_INPUT,
      label: 'Pricing Rule',
      store: 'settingsCatalogPricingRules',
      options: {
        label: 'pricing_rule_name',
      },
      // firstSelect: true,
    },
    pricing_period_days: {
      type: TEXT_INPUT,
      label: 'Pricing Period (days) override',
      // firstSelect: true,
      rules: 'numeric',
      placeholder: 'Type...',
    },
    pricing_type_id: {
      type: SELECT_INPUT,
      label: 'Pricing type override',
      store: 'pricingTypesList',
      options: {
        label: 'name',
      },
      // firstSelect: true,
    },
    rate: {
      type: L_CURRENCY_MASK_INPUT,
      label: 'Amount',
      placeholder: 'Type...',
      rules: 'numeric',
    },
    price_tier_override: {
      type: CHECKBOX_LEF,
      label: 'Pricing Tiers Override',
    },
    price_tier_id: {
      type: TEXT_INPUT,
      label: 'Price Tiers',
    },
    // status: {
    //   type: CHECKBOX,
    //   label: 'Status',
    // },
  }
  return {
    fields,
    tableColumns,
    ACCESS_ABILITY_RATES,
  }
}
