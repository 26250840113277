<template>
  <div>
    <div class="d-flex justify-content-end">
      <feather-icon
        v-if="organizationRequiresEditConfirmation"
        v-b-tooltip.noninteractive.hover.bottom
        :title="$t('Edit')"
        :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
        size="36"
        class="ml-1 cursor-pointer"
        @click="isEditAble()"
      />
    </div>
    <error-alert
      :fields="fields"
      :error="error"
    />
    <b-form>
      <validation-observer
        ref="ratesCreateVal"
      >
        <div
          v-for="priceTier in getActivePriceTierList()"
          :key="priceTier.id"
        >
          <div class="d-flex align-center">
            <img
              width="30px"
              height="30px"
              :src="cscart"
              alt="logo"
            >
            <label
              style="margin-left: 10px; margin-top: 5px;"
              class="font-medium-5 font-weight-bolder"
            >{{ priceTier.label }}</label>
          </div>
          <b-row>
            <b-col md="6">
              <component
                :is="fields[field].type"
                v-for="field in ['rate']"
                :key="field"
                :ref="`${field}_ref`"
                v-model="ratesForm[priceTier.id][field]"
                v-bind="getProps(field, priceTier)"
                :name="priceTier.id + field"
                :is-editable="!$can(ACCESS_ABILITY_RATES.action, ACCESS_ABILITY_RATES.subject) || isEdit"
                :is-required-field="priceTier.is_required"
              />
            </b-col>
            <span class="mt-2 font-medium-5">{{ priceTier.is_default ? '*' : '' }}</span>
          </b-row>
        </div>
        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="!isFormChanged"
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="$router.push({name: 'home-main-catalog-list'})"
              >
                {{ $t('Back to List') }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="loader"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Cancel') }}
              </b-button>
            </div>
            <div>
              <b-button
                v-if="$can(ACCESS_ABILITY_RATES.action, ACCESS_ABILITY_RATES.subject)"
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                :disabled="!isFormChanged"
                @click="submit"
              >
                {{ $t('SAVE') }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </b-form>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>
<script>
import { required } from '@/libs/validations/validations'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { ValidationObserver } from 'vee-validate'
import {
  BButton, BCol, BForm, BRow, VBTooltip,
} from 'bootstrap-vue'
import { scrollToError } from '@core/utils/utils'
import { mapGetters, mapState } from 'vuex'
import cscart from '@/assets/images/integrations/cs_cart.svg'
import store from '@/store'
import config from '../ratesConfig'

export default {
  name: 'RatesForm',
  components: {
    ErrorAlert,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      error: {},
      required,
      cscart,
      isSubmit: false,
      isDefaultID: '',
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    ...mapState('rates', ['ratesForm']),
    ...mapState('rates', ['emptyForm']),
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    getCatalog() {
      return this.$store.state[this.CATALOG_MODULE_NAME].catalog
    },
    ratesFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].catalogRatesForm
    },
    isFormChanged() {
      return JSON.stringify(this.ratesForm) !== this.ratesFormClone
    },
    ...mapState('listModule', { priceTiers: 'priceTierList' }),
    ...mapGetters('listModule', ['getActivePriceTierList']),
  },
  mounted() {
    const defaultTear = this.getActivePriceTierList().find(tier => tier.is_default)
    if (defaultTear) {
      this.isDefaultID = defaultTear.id
    }
  },
  methods: {
    isEditAble() {
      this.isEdit = !this.isEdit
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.isSubmit)
    },
    submit() {
      this.error = {}
      this.isSubmit = true

      this.$refs.ratesCreateVal.validate()
        .then(success => {
          if (success) {
            const data = Object.values(this.ratesForm).map(item => this.mappingFields(Object.keys(this.fields), item, {
              product: String(this.getCatalog.id),
              pricing_rule_id: item.pricing_rule_id?.id ?? null,
              pricing_type_id: item.pricing_type_id?.id ?? null,
              price_tier_override: item.price_tier_override,
            }))
            if (this.showAlertOnEmpty()) {
              return
            }
            this.sendNotification(this, { rates: data }, `${this.MODULE_NAME}/update`).then(() => {
              this.clear()
            }).catch(err => {
              this.error = err.response?.data
            })
            this.$router.push({
              name: 'home-catalog-phy-properties',
              params: { id: this.getCatalog?.id },
            })
          } else {
            scrollToError(this, this.$refs.ratesCreateVal)
          }
        })
    },
    showAlertOnEmpty() {
      const transformedArray = Object.keys(this.ratesForm).map(key => this.ratesForm[key])
      const emptyDefaultItem = transformedArray.find(item => item.price_tier_id === this.isDefaultID).rate
      if (!emptyDefaultItem) {
        this.$bvToast.toast(this.$t('The default rate field should not be empty'), {
          variant: 'danger',
          solid: true,
          title: this.$t('Error'),
        })
        return true
      }
      return false
    },
    clear() {
      this.$refs.ratesCreateVal.reset()
      this.$emit('fetchData')
    },
    loader() {
      this.$refs.ratesCreateVal.reset()
      const smth = JSON.parse(this.ratesFormClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_RATES_FORM`, smth)
    },
    cancel() {
      this.clear()
      this.$router.push({ name: 'home-main-catalog-list' })
    },
    getProps(fieldName, priceTier) {
      const dynamicRulesOfField = ['rate']

      const props = {
        is: this.fields[fieldName].type,
        field: this.fields[fieldName],
        name: fieldName,
      }
      if (dynamicRulesOfField.includes(fieldName)) {
        const form = this.ratesForm[priceTier.id]
        // props.field = { ...this.fields[fieldName], rules: this.isRequired(form) ? 'required' : '' }
      }

      return props
    },
    // isRequired(form) {
    //   return (form.rate || form.pricing_rule_id || form.pricing_type_id || form.pricing_period_days || form.price_tier_override)
    // },
  },
  setup() {
    const ecommerceList = {
      cscart: {
        image: 'cs_cart.svg',
        title: 'CS.Cart',
        class: 'border-0',
      },
    }
    const MODULE_NAME = 'rates'
    const CATALOG_MODULE_NAME = 'catalogs'
    const MODULE_NAME_CLONE = 'cloneData'
    const { fields, ACCESS_ABILITY_RATES } = config()
    return {
      MODULE_NAME,
      fields,
      CATALOG_MODULE_NAME,
      MODULE_NAME_CLONE,
      ACCESS_ABILITY_RATES,
    }
  },
}
</script>
